import React from 'react';

import Layout from '../../components/layout/layout';
import Container from '../../components/container/container';

import * as styles from './privacy-policy.module.scss';

export default function PrivacyPolicyPage() {
  return (
    <Layout
      title="Privacy Policy"
      className={styles.page}
      includeContactSection={false}
    >
      <Container>
        <h1>Privacy Policy</h1>
        <p>
          This privacy policy sets out how Timeless Design and Build Ltd uses
          and protects any information that you give us when you use this
          website.
        </p>
        <p>
          Click is committed to ensuring that your privacy is protected. Should
          we ask you to provide certain information by which you can be
          identified when using this website, then you can be assured that it
          will only be used in accordance with this privacy statement.
        </p>
        <p>
          Click may change this policy from time to time by updating this page.
          You should check this page from time to time to ensure that you are
          happy with any changes. This policy is effective from April 2013.
        </p>

        <h3>What we collect</h3>
        <p>We may collect the following information:</p>

        <ul>
          <li>Name and job title</li>
          <li>Contact information including email address</li>
          <li>
            Demographic information such as postcode, preferences and interests
          </li>
          <li>Other information relevant to customer surveys and/or offers</li>
          <li>What we do with the information we gather</li>
        </ul>
        <p>
          We require this information to understand your needs and provide you
          with a better service, and in particular for the following reasons:
        </p>

        <h3>Internal record keeping</h3>
        <p>We may use the information to improve our products and services.</p>
        <p>
          We may periodically send promotional emails about new products,
          special offers or other information which we think you may find of
          interest using the email address which you have provided.
        </p>
        <p>
          From time to time, we may also use your information to contact you for
          market research purposes. We may contact you by email, phone, fax or
          mail. We may use the information to customise the website according to
          your interests
        </p>

        <h3>Security</h3>
        <p>
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorised access or disclosure, we have put in place
          suitable physical, electronic and managerial procedures to safeguard
          and secure the information we collect online.
        </p>

        <h3>We use performance cookies</h3>
        <p>
          These cookies only collect information about website usage for the
          benefit of the website operator. By using our website, you agree that
          we can place these types of cookies on your device.
        </p>

        <h3>Links to other websites</h3>
        <p>
          Our website may contain links to other websites of interest. However,
          once you have used these links to leave our site, you should note that
          we do not have any control over the other websites. Therefore, we
          cannot be responsible for the protection and privacy of any
          information which you provide whilst visiting such sites and such
          sites are not governed by this privacy statement. You should exercise
          caution and look at the privacy statement applicable to the website in
          question.
        </p>

        <h3>Controlling your personal information</h3>
        <p>
          You may choose to restrict the collection or use of your personal
          information in the following ways:
        </p>
        <ul>
          <li>
            whenever you are asked to fill in a form on the website, look for
            the box that you can click to indicate that you do not want the
            information to be used by anybody for direct marketing purposes
          </li>
          <li>
            if you have previously agreed to us using your personal information
            for direct marketing purposes, you may change your mind at any time
            by writing to or emailing us at info@haveyouclicked.co.uk
          </li>
        </ul>

        <p>
          We will not sell, distribute or lease your personal information to
          third parties unless we have your permission or are required by law to
          do so. We may use your personal information to send you promotional
          information about third parties which we think you may find
          interesting if you tell us that you wish this to happen.
        </p>
        <p>
          You may request details of personal information which we hold about
          you under the Data Protection Act 1998. A small fee will be payable.
          If you would like a copy of the information held on you please write
          to Timeless Design and Build, 48 Marshalls Drive, St Albans, Herts
          AL14RQ
        </p>
        <p>
          If you believe that any information we are holding on you is incorrect
          or incomplete, please write to or email us as soon as possible, at the
          above address. We will promptly correct any information found to be
          incorrect.
        </p>
      </Container>
    </Layout>
  );
}
